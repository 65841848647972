
import { defineComponent, PropType, Ref, ref, onUnmounted } from 'vue';
import { AdministrationParams, sortPayload }   from '@/types';
import { TableColumn, TableRow }               from '@/components/common/table/table.types';
import cSearchbar                              from '@/components/common/searchbar/searchbar.component.vue';
import cButton                                 from '@/components/common/button/button.component.vue';
import cTable                                  from '@/components/common/table/table.component.vue';
import cPagination                             from '@/components/common/pagination/pagination.component.vue';
import { debounce }                              from 'lodash-es';

export default defineComponent({
  name: 'CAdministrationTemplate',
  components: { cTable, cButton, cSearchbar, cPagination },

  props: {
    columns: {
      type: Array as PropType<TableColumn[]>,
      required: true,
    },
    rows: {
      type: Array as PropType<TableRow[]>,
      required: true,
    },
    buttonLabel: {
      type: String,
      default: 'save',
    },
    paginationName: {
      type: String,
      required: true,
    },
    params: {
      type: Object as PropType<AdministrationParams>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    }
  },
  emits: [ 'paramsChange', 'addNewElement', 'editElement' ],
  setup(props, { emit }) {
    const selectedItemUuid: Ref<string> = ref('');
    const search: Ref<string> = ref('');
    const updateAdministrationParams = (param: Record<string, string | number | string[]>): void => {
      const updatedParams: Partial<AdministrationParams> = Object.assign({}, props.params, param);
      emit('paramsChange', updatedParams);
    };
    const handleSort = (event: sortPayload) => {
      updateAdministrationParams({ 'sortBy[0].direction': event.direction, 'sortBy[0].field': event.field })
    };
    const handleFilter = (event: any) => {
      const uuidTable: string[] = event.values.map((value: Record<string,string>) => value.uuid || value.name);
      updateAdministrationParams({ [event.name]: uuidTable });
    };

    const handleFilterBySearchQuery = debounce((searchQuery: string) => {
      search.value = searchQuery;
      updateAdministrationParams({ searchQuery })
    }, 500)

    const addNewElement = (): void => {
      emit('addNewElement');
    };

    const showTableItem = async (itemUuid: string, action?: string, row?: TableRow): Promise<void> => {
      selectedItemUuid.value = itemUuid;

      if (row && row.email) {
        search.value = row.email.toString()
      }

      if (action) {
        emit('editElement', itemUuid, action);
      } else {
        emit('editElement', itemUuid);
      }
    };


    onUnmounted(() => {
      handleFilterBySearchQuery('')
    });

    return {
      search,
      handleFilter,
      handleSort,
      updateAdministrationParams,
      addNewElement,
      showTableItem,
      handleFilterBySearchQuery,
      selectedItemUuid,
    };
  },
});
