
import { defineComponent, onMounted, ref, Ref } from 'vue';
import { useGlobalTranslations }                from '@/hooks/use-translations';
import cButton                                  from '@/components/common/button/button.component.vue';
import cCloseButton                             from '@/components/common/close-button/close-button.component.vue';

export default defineComponent({
  name: 'CConfirmationModal',
  components: { cButton, cCloseButton },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    isModalVisible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['accept', 'decline', 'close-modal'],
  setup() {
    const modal: Ref<HTMLElement | null> = ref(null);
    onMounted(() => {
      if (modal.value) {
        modal.value.focus();
      }
    });
    return {
      useGlobalTranslations,
      modal
    };
  },
});
