
import { defineComponent, PropType }              from 'vue';
import { useGlobalTranslations, useTranslations } from '@/hooks/use-translations';
import CButton                                    from '@/components/common/button/button.component.vue';
import { FormMode }                               from '@/views/administration/administration.types';
import { UserFormMode }                           from '@/views/administration/views/administration-users/administration-users.types';
import CElementWithConfirmation                   from '@/components/common/element-with-confirmation/element-with-confirmation.component.vue';

export default defineComponent({
  name: 'CAdministrationFormButtons',
  components: { CElementWithConfirmation, CButton },
  props: {
    formMode: {
      type: String as PropType<FormMode | UserFormMode>,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    isCurrentLoggedUser: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['cancel', 'add-next', 'save', 'remove'],
  setup() {
    return {
      FormMode,
      useGlobalTranslations,
      useTranslations,
    }
  }
});
