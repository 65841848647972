
import { defineComponent, PropType, Ref, ref } from 'vue';
import { SearchbarSelectItem } from '@/types';
import CSearchbarListItem
  from '@/views/administration/components/delete-confirmation/components/searchbar-list-item/searchbar-list-item.component.vue';

export default defineComponent({
  name: 'CListItemsWithSearchbar',
  components: { CSearchbarListItem },
  props: {
    items: {
      type: Array as PropType<SearchbarSelectItem[]>,
      default: () => [{
        uuid: '',
        label: ''
      }],
    },
    selected: {
      type: String,
      default: '',
    },
    searchbarLabel: {
      type: String,
      required: true
    },
    replaceLabel: {
      type: String,
      required: true
    }
  },
  emits: ['click'],
  setup(props, context) {
    const searchValue: Ref<string> = ref('');
    const isFocused: Ref<boolean> = ref(false);
    const selectedItemUuid: Ref<string> = ref('')
    const itemsCopy: Ref<SearchbarSelectItem[]> = ref(props.items);

    const setSelectedItemUuid = (uuid: string): void => {
      context.emit('click', uuid);
      selectedItemUuid.value = uuid
    }

    const onInputValueChanged = (): void => {
      itemsCopy.value = props.items.filter(item => item.label.toLowerCase().includes(searchValue.value.toLowerCase()))
    };

    const blurWithDelay = (): void => {
      setTimeout(() => {
        isFocused.value = false;
      }, 200);
    };

    return {
      searchValue,
      isFocused,
      itemsCopy,
      selectedItemUuid,
      setSelectedItemUuid,
      blurWithDelay,
      onInputValueChanged,
    }
  }
});
