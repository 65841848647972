
import { defineComponent, Ref, ref } from 'vue';
import { Popover }                   from '@headlessui/vue';
import CConfirmationModal            from '@/components/common/modal/confirmation-modal.component.vue';
import CButton                       from '@/components/common/button/button.component.vue';
import { useGlobalTranslations }     from '@/hooks/use-translations';

export default defineComponent({
  name: 'CElementWithConfirmation',
  components: {
    CButton,
    CConfirmationModal,
    Popover,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['accept'],
  setup(_, context) {
    const open: Ref<boolean> = ref(false);
    const accept = (): void => {
      open.value = false;
      context.emit('accept');
    };
    return {
      open,
      accept,
      useGlobalTranslations,
      toggleTooltip: (visible: boolean): void => {
        open.value = visible;
      },
    };
  },
});
