import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_button = _resolveComponent("c-button")
  const _component_c_confirmation_modal = _resolveComponent("c-confirmation-modal")
  const _component_popover = _resolveComponent("popover")

  return (_openBlock(), _createBlock(_component_popover, {
    id: "elementWithConfirmation",
    class: ["h-12 flex items-center relative", _ctx.small ? '2xl:w-32 xl:w-32 lg:w-32 w-32' : '2xl:w-72 xl:w-52 lg:w-44 w-40']
  }, {
    default: _withCtx(() => [
      _createVNode(_component_c_button, {
        is: "red",
        "button-label": _ctx.useGlobalTranslations(`button.delete`),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleTooltip(true)))
      }, null, 8, ["button-label"]),
      (_ctx.open)
        ? (_openBlock(), _createBlock(_component_c_confirmation_modal, {
            key: 0,
            description: 'Are you sure?',
            "is-modal-visible": _ctx.open,
            title: 'Confirm removing',
            onAccept: _ctx.accept,
            onCloseModal: _cache[2] || (_cache[2] = ($event: any) => {_ctx.open=false;}),
            onDecline: _cache[3] || (_cache[3] = ($event: any) => {_ctx.open=false;})
          }, null, 8, ["is-modal-visible", "onAccept"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class"]))
}