import { withModifiers as _withModifiers, createVNode as _createVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withCtx as _withCtx, withKeys as _withKeys, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full h-1/5 flex items-center justify-between" }
const _hoisted_2 = { class: "text-primary-500 text-16 font-semibold capitalize" }
const _hoisted_3 = { class: "flex-grow w-full py-f13" }
const _hoisted_4 = { class: "text-wrap" }
const _hoisted_5 = { class: "w-full h-1/4 flex justify-between py-f8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_close_button = _resolveComponent("c-close-button")
  const _component_c_button = _resolveComponent("c-button")

  return (_ctx.isModalVisible)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: "body"
      }, [
        _createVNode("div", {
          class: "w-screen h-screen flex justify-center items-center opacity-70 bg-black absolute",
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('close-modal')), ["exact"]))
        }),
        _createVNode("div", {
          ref: "modal",
          class: "w-1/5 min-w-27 h-96 bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2\n                rounded-xl flex flex-col justify-between divide-y p-f21",
          tabindex: "1",
          onKeypress: [
            _cache[5] || (_cache[5] = _withKeys(($event: any) => (_ctx.$emit('accept')), ["enter"])),
            _cache[6] || (_cache[6] = _withKeys(($event: any) => (_ctx.$emit('close-modal')), ["esc"]))
          ]
        }, [
          _createVNode("div", _hoisted_1, [
            _createVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1),
            _createVNode(_component_c_close_button, {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close-modal')))
            })
          ]),
          _createVNode("div", _hoisted_3, [
            _createVNode("span", _hoisted_4, _toDisplayString(_ctx.description), 1)
          ]),
          _createVNode("div", _hoisted_5, [
            _createVNode(_component_c_button, {
              small: "",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('accept')))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.useGlobalTranslations('button.accept')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_c_button, {
              is: "red",
              small: "",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('decline')))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.useGlobalTranslations('button.cancel')), 1)
              ]),
              _: 1
            })
          ])
        ], 544)
      ]))
    : _createCommentVNode("", true)
}