import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-between h-full min-h-0" }
const _hoisted_2 = { class: "flex flex-col min-h-0 gap-20" }
const _hoisted_3 = { class: "mt-f21 text-error font-bold" }
const _hoisted_4 = { class: "flex justify-between mt-f13" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_list_items_with_searchbar = _resolveComponent("c-list-items-with-searchbar")
  const _component_c_button = _resolveComponent("c-button")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("p", _hoisted_3, _toDisplayString(_ctx.errorMessage), 1),
      _createVNode(_component_c_list_items_with_searchbar, {
        items: _ctx.items,
        "replace-label": _ctx.useTranslations('sidePanel.replace'),
        "searchbar-label": _ctx.useGlobalTranslations('search'),
        onClick: _ctx.setSelectedValue
      }, null, 8, ["items", "replace-label", "searchbar-label", "onClick"])
    ]),
    _createVNode("div", _hoisted_4, [
      _createVNode(_component_c_button, {
        is: "light",
        "button-label": _ctx.useGlobalTranslations('button.back'),
        onClick: _ctx.goBackToEditForm
      }, null, 8, ["button-label", "onClick"]),
      _createVNode(_component_c_button, {
        "button-label": _ctx.useGlobalTranslations('button.replace'),
        disabled: !_ctx.selectedValue,
        onClick: _ctx.changeAssignedEntity
      }, null, 8, ["button-label", "disabled", "onClick"])
    ])
  ]))
}