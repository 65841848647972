
import CButton from '@/components/common/button/button.component.vue';
import { useGlobalTranslations, useTranslations } from '@/hooks/use-translations'
import { defineComponent, PropType, ref, Ref } from 'vue';
import { SearchbarSelectItem } from '@/types';
import CListItemsWithSearchbar
  from '@/views/administration/components/delete-confirmation/components/list-items-with-searchbar/list-items-with-searchbar.component.vue';

export default defineComponent({
  name: 'CDeleteConfirmation',
  components: {
    CListItemsWithSearchbar,
    CButton
  },
  props: {
    selectLabel: {
      type: String,
      required: true
    },
    items: {
      type: Array as PropType<SearchbarSelectItem[]>,
      default: () => []
    },
    errorMessage: {
      type: String,
      required: true
    }
  },
  emits: ['change-assigned-entity', 'back-to-previous-panel'],
  setup(props, { emit }) {
    const selectedValue: Ref<string> = ref('')
    const setSelectedValue = (uuid: any) => {
      selectedValue.value = uuid
    }

    const goBackToEditForm = () => {
      emit('back-to-previous-panel')
    }

    const changeAssignedEntity = () => {
      emit('change-assigned-entity', selectedValue.value)
    }

    return {
      selectedValue,
      setSelectedValue,
      goBackToEditForm,
      changeAssignedEntity,
      useGlobalTranslations,
      useTranslations
    }

  }
})
