
import { defineComponent, PropType } from 'vue';
import { SearchbarSelectItem } from '@/types';

export default defineComponent({
  name: 'CSearchbarListItem',
  props: {
    isSelected: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object as PropType<SearchbarSelectItem>,
      required: true
    }
  },
  emits: ['click']
});
