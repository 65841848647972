import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "c-administration-template" }
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { class: "flex flex-col c-administration-template__table__wrapper scroll-style" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_searchbar = _resolveComponent("c-searchbar")
  const _component_c_button = _resolveComponent("c-button")
  const _component_c_table = _resolveComponent("c-table")
  const _component_c_pagination = _resolveComponent("c-pagination")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_c_searchbar, {
        class: "w-1/5",
        search: _ctx.search,
        "onUpdate:search": _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleFilterBySearchQuery($event)))
      }, null, 8, ["search"]),
      _createVNode(_component_c_button, {
        "button-label": _ctx.buttonLabel.toUpperCase(),
        class: "w-25",
        onClick: _ctx.addNewElement
      }, null, 8, ["button-label", "onClick"])
    ]),
    _createVNode("div", _hoisted_3, [
      _withDirectives(_createVNode(_component_c_table, {
        class: "table-fixed",
        columns: _ctx.columns,
        rows: _ctx.rows,
        "selected-item-uuid": _ctx.selectedItemUuid,
        onClick: _ctx.showTableItem,
        onDataEvent: _ctx.showTableItem,
        onFilter: _ctx.handleFilter,
        onSort: _ctx.handleSort
      }, null, 8, ["columns", "rows", "selected-item-uuid", "onClick", "onDataEvent", "onFilter", "onSort"]), [
        [_directive_loading, _ctx.isLoading]
      ])
    ]),
    _createVNode(_component_c_pagination, {
      name: _ctx.paginationName,
      onUpdatePagination: _ctx.updateAdministrationParams
    }, null, 8, ["name", "onUpdatePagination"])
  ]))
}