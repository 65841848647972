import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center gap-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_button = _resolveComponent("c-button")
  const _component_c_element_with_confirmation = _resolveComponent("c-element-with-confirmation")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.formMode === _ctx.FormMode.EDIT)
      ? (_openBlock(), _createBlock(_component_c_button, {
          key: 0,
          is: "light",
          "button-label": _ctx.useGlobalTranslations('button.cancel'),
          class: "w-1/3 uppercase truncate",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancel')))
        }, null, 8, ["button-label"]))
      : _createCommentVNode("", true),
    (_ctx.formMode === _ctx.FormMode.ADD)
      ? (_openBlock(), _createBlock(_component_c_button, {
          key: 1,
          is: "light",
          "button-label": _ctx.useGlobalTranslations(`button.addNext`),
          class: "w-2/5 uppercase truncate",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('add-next')))
        }, null, 8, ["button-label"]))
      : _createCommentVNode("", true),
    (_ctx.formMode === _ctx.FormMode.EDIT && !_ctx.isCurrentLoggedUser)
      ? (_openBlock(), _createBlock(_component_c_element_with_confirmation, {
          key: 2,
          class: "w-1/3 uppercase",
          onAccept: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('remove')))
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_c_button, {
      "button-label": _ctx.useGlobalTranslations('button.save'),
      class: ['uppercase truncate', _ctx.formMode === _ctx.FormMode.EDIT? 'w-1/3':'w-2/5'],
      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('save')))
    }, null, 8, ["button-label", "class"])
  ]))
}