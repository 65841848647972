import { toDisplayString as _toDisplayString, vModelText as _vModelText, createVNode as _createVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "flex flex-col overflow-hidden min-h-0" }
const _hoisted_2 = { class: "text-14 pt-f5 text-gray-850 leading-8 mb-3" }
const _hoisted_3 = { class: "text-14 pt-f5 text-gray-850 leading-8" }
const _hoisted_4 = { class: "flex flex-col overflow-y-auto gap-1 c-administration-template__table__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_searchbar_list_item = _resolveComponent("c-searchbar-list-item")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("label", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.searchbarLabel) + " ", 1),
      _withDirectives(_createVNode("input", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchValue = $event)),
        class: "w-full h-14 pl-f8 bg-gray-200 rounded-full relative flex items-center text-14 focus:outline-none",
        onFocusin: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isFocused = true)),
        onFocusout: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.blurWithDelay && _ctx.blurWithDelay(...args))),
        onInput: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onInputValueChanged && _ctx.onInputValueChanged(...args)))
      }, null, 544), [
        [_vModelText, _ctx.searchValue]
      ])
    ]),
    _createVNode("span", _hoisted_3, _toDisplayString(_ctx.replaceLabel), 1),
    _createVNode("div", _hoisted_4, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.itemsCopy, (item) => {
        return (_openBlock(), _createBlock(_component_c_searchbar_list_item, {
          key: item.uuid,
          "is-selected": _ctx.selectedItemUuid === item.uuid,
          item: item,
          onClick: _ctx.setSelectedItemUuid
        }, null, 8, ["is-selected", "item", "onClick"]))
      }), 128))
    ])
  ]))
}